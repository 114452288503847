<template>
  <div>
    <v-select
      label="Select Start Time"
      v-model="startTime"
      :items="timeToPick"
      @change="saveStartTime"
    ></v-select>
    <v-select
      label="Select End Time"
      v-model="timeEnd"
      :items="timeToPick"
      @change="saveTimeEnd"
    ></v-select>
  </div>
</template>

<script>
import { format } from 'date-fns';

export default {
  name: 'selectedJobTimePicker',
  props: [
    'initialStart',
    'initialEnd',
  ],
  data() {
    return {
      startTime: '08:00',
      timeEnd: '18:00',
      timeSet: false,
      timeToPick: [
        '24:00', '24:30', '01:00', '01:30', '22:00', '02:30', '03:00', '03:30', '04:00', '04:30',
        '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30',
        '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30',
        '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30',
        '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30',
      ],
    };
  },
  methods: {
    saveStartTime() {
      this.$emit('startTimePicked', this.startTime);
    },
    saveTimeEnd() {
      this.$emit('endTimePicked', this.timeEnd);
    },
    setInitialValues() {
      if (this.initialStart && this.timeSet === false) {
        const sT = new Date(this.initialStart);
        const eT = new Date(this.initialEnd);
        this.startTime = format(sT, 'HH:mm');
        this.timeEnd = format(eT, 'HH:mm');
        this.timeSet = true;
      }
    },
  },
  mounted() {
    this.timer = setInterval(() => {
      this.setInitialValues();
    }, 300);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
