<template>
  <v-date-picker
    v-model="dates"
    range
    no-title
    color="orange lighten-1"
    width="100%"
  ></v-date-picker>
</template>

<script>
import { format } from 'date-fns';

export default {
  name: 'SortedRangeDatePicker',
  props: [
    'maintenanceRecord',
  ],
  data() {
    return {
      dates: [],
    };
  },
  watch: {
    dates() {
      this.$emit('dateChanged', this.dates);
    },
  },
  methods: {
    setInitialDates() {
      if (this.maintenanceRecord.start) {
        this.dates.push(format(new Date(this.maintenanceRecord.start), 'YYY-MM-dd'));
        this.dates.push(format(new Date(this.maintenanceRecord.end), 'YYY-MM-dd'));
      }
    },
  },
  mounted() {
    this.setInitialDates();
  },
};
</script>
