<template>
  <v-row justify="center">
    <v-dialog
      :value=true
      persistent
      max-width="600px"
    >
      <v-card>
        <p class="text-center pt-8">
          <span class="text-h5">Worker: {{ selectedWorker.name }}</span>
        </p>
        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <sorted-range-date-picker
                  :maintenanceRecord="holidayRecord"
                  @dateChanged="setDates"
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
              >
                <time-start-end-picker
                  v-if="dates"
                  :initialStart="holidayRecord.start"
                  :initialEnd="holidayRecord.end"
                  @startTimePicked="startTimePicked"
                  @endTimePicked="endTimePicked"
                />
                <v-textarea
                  v-if="dates"
                  v-model="description"
                  placeholder="description"
                  solo
                  rows="3"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="dates" class="pt-0">
                <v-card-subtitle
                 v-if="dates"
                 class="pb-3 pt-0"
                >
                  {{ selectedWorker.name }} will be away
                  from: {{ dates[0] | prettyDate }} {{ timeStart }}
                  to: {{ dates[1] | prettyDate }} {{ timeEnd }}
                  for: {{ description }}
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-row class="pb-5 pr-4">
          <v-col class="pt-0">
            <v-card-actions class="float-right">
              <v-btn
                @click="saveChanges"
                small
                color="orange darken-1"
              >
                <v-icon left>
                  mdi-pencil
                </v-icon>
                Save
              </v-btn>
              <v-btn
                small
                color="blue-grey lighten-4"
                @click="closeSetHoliday"
              >
                <v-icon left>
                  mdi-close
                </v-icon>
                Abandon Changes
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TimeStartEndPicker from '@/components/Tools/TimeStartEndPicker.vue';
import SortedRangeDatePicker from '@/components/Tools/SortedRangeDatePicker.vue';
import { format } from 'date-fns';
import axios from '../../axios';

export default {
  name: 'EditHoliday',
  props: [
    'holidayRecord',
  ],
  components: {
    TimeStartEndPicker,
    SortedRangeDatePicker,
  },
  data() {
    return {
      dates: [],
      description: '',
      timeStart: '08:00',
      timeEnd: '18:00',
      setCount: 0,
    };
  },
  computed: {
    selectedWorker() {
      return this.$store.getters.selectedWorker;
    },
    token() {
      return this.$store.state.token;
    },
  },
  methods: {
    startTimePicked(value) {
      this.timeStart = value;
    },
    endTimePicked(value) {
      this.timeEnd = value;
    },
    setDates(value) {
      if (this.setCount === 0) {
        this.setCount = 1;
        return;
      }
      const sortedDates = [];
      if (value[0] && !value[1]) {
        sortedDates.push(value[0]);
        sortedDates.push(value[0]);
      }
      if (value[0] && value[1] && new Date(value[0]).getTime() < new Date(value[1]).getTime()) {
        sortedDates.push(value[0]);
        sortedDates.push(value[1]);
      }
      if (value[0] && value[1] && new Date(value[0]).getTime() > new Date(value[1]).getTime()) {
        sortedDates.push(value[1]);
        sortedDates.push(value[0]);
      }
      this.dates = sortedDates;
    },
    setInitialValues() {
      this.dates.push(format(new Date(this.holidayRecord.start), 'YYY-MM-dd'));
      this.dates.push(format(new Date(this.holidayRecord.end), 'YYY-MM-dd'));
      this.description = this.holidayRecord.description;
    },
    saveChanges() {
      const postData = {};
      postData.record = this.holidayRecord.id;
      postData.worker = this.selectedWorker.id;
      postData.dates = this.dates;
      postData.timeStart = this.timeStart;
      postData.timeEnd = this.timeEnd;
      postData.description = this.description;
      postData.timeOffset = new Date().getTimezoneOffset();
      axios.post(`/workerUnavailableDates/updateHoliday.json?token=${this.token}`, postData)
        .then(() => {
          this.$store.dispatch('refreshWorkers');
          this.$emit('closeEditHoliday');
        });
    },
    closeSetHoliday() {
      this.$emit('closeEditHoliday');
    },
  },
  mounted() {
    this.setInitialValues();
  },
};
</script>
